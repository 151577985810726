import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import Tag from "../components/tag"
import Category from "../components/category"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  const description = data.site.siteMetadata.description
  const logo = data.logo.childImageSharp.gatsbyImageData

  const { previous, next } = pageContext
  let featuredImg = post.frontmatter.featuredImage
    ? post.frontmatter.featuredImage.childImageSharp
    : null
  let imgHeight = featuredImg ? featuredImg.original.height : 0
  let imgWidth = featuredImg ? featuredImg.original.width : 0
  let isLandscape = imgWidth > imgHeight
  const tags = post.frontmatter.tags
  const categories = post.frontmatter.categories

  return (
    <Layout className="post-wrapper" description={description} logo={logo}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.featuredImage}
      />
      <div className="post-wrapper">
        {featuredImg && isLandscape ? (
          <GatsbyImage
            className={"featured-landscape"}
            image={featuredImg.gatsbyImageData}
            alt={post.frontmatter.featuredImageAlt}
          />
        ) : null}
        {post.frontmatter.layout !== "page" ? (
          <div class="small-info">
            <span class="small-info">O texto a seguir pode conter links afiliados/patrocinados. Confira mais informações <a href="/privacidade">aqui</a>.</span>
          </div>
        ) : null}
        <h1 itemProp="headline">{post.frontmatter.title}</h1>
        {post.frontmatter.layout !== "page" ? (
          <span>
            {categories
              .map(t => <Category key={t} catName={t} />)
              .reduce((prev, curr) => [prev, ", ", curr])}
            {" • "}
          </span>
        ) : null}
        {post.frontmatter.layout !== "page" ? (
          <span>{post.frontmatter.date}</span>
        ) : null}
        {featuredImg && !isLandscape ? (
          <GatsbyImage
            className={"featured-portrait"}
            image={featuredImg.gatsbyImageData}
            alt={post.frontmatter.featuredImageAlt}
          />
        ) : null}
        <div className="post-text">
          <MDXRenderer>{post.body}</MDXRenderer>
          <hr />
          {post.frontmatter.featuredImageCaption ? (
            <>
              <b>Imagem de destaque: </b>
              <section
                dangerouslySetInnerHTML={{
                  __html: post.frontmatter.featuredImageCaption,
                }}
              />
            </>
          ) : null}
          {tags && (
            <div className="tag-row">
              <b>Etiquetas: </b>
              {tags
                .map(t => <Tag key={t} tagName={t} />)
                .reduce((prev, curr) => [prev, ", ", curr])}
            </div>
          )}
        </div>

        <nav className="blog-post-nav">
          <div className="previous-post-nav">
            {previous && (
              <Link
                className="post-nav-link"
                to={"/" + previous.fields.slug}
                rel="prev"
              >
                ← Anterior
              </Link>
            )}
          </div>
          <div className="right-post-nav">
            {next && (
              <Link
                className="post-nav-link"
                to={"/" + next.fields.slug}
                rel="next"
              >
                Próximo →
              </Link>
            )}
          </div>
        </nav>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 200)
      body
      fields {
        slug
      }
      frontmatter {
        title
        tags
        categories
        layout
        date(formatString: "DD/MM/YYYY")
        excerpt
        featuredImageCaption
        featuredImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 900, layout: CONSTRAINED)
            original {
              height
              width
            }
          }
        }
        featuredImageAlt
      }
    }
  }
`
