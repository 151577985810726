import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ListaPage = ({ data, location }) => {
  const description = data.site.siteMetadata.description
  const logo = data.logo.childImageSharp.gatsbyImageData
  const { author } = data.site.siteMetadata
  const posts = data.allMdx.edges
  const pageTitle = "Lista"

  const convertPost = node => {
    const title = node.frontmatter.title || node.fields.slug
    return (
      <li key={node.fields.slug}>
        <Link to={"/" + node.fields.slug}>{title}</Link>
      </li>
    )
  }

  return (
    <Layout description={description} logo={logo}>
      <Seo
        title={pageTitle}
        description="Lista de acesso rápido aos últimos posts e a destaques pontuais."
      />
      <div className="insta-list">
        <GatsbyImage
          image={data.avatar.childImageSharp.gatsbyImageData}
          alt={author.name}
          className="avatar"
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
        <p>{author.name}</p>
        <h2>Últimos posts</h2>
        <ul>{posts.map(({ node }) => convertPost(node))}</ul>
      </div>
    </Layout>
  )
}

export default ListaPage

export const pageQuery = graphql`
  {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 100, height: 100, quality: 95)
      }
    }
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
        social {
          twitter
        }
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
      filter: { frontmatter: { layout: { ne: "page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
