import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faAsterisk } from "@fortawesome/free-solid-svg-icons"

const OverlayMenu = ({ showMenu, closeMenu }) => {
  return (
    <div className={"overlay-menu" + (showMenu ? " open" : "")}>
      <button
        className="close-button"
        aria-label="Fechar menu"
        onClick={() => {
          closeMenu()
        }}
      >
        <FontAwesomeIcon icon={faTimes} size="4x" />
      </button>
      <nav>
        <span className="main-menu-item">
          <Link to="/" rel="home">
            Página Inicial
          </Link>
        </span>
        <span className="main-menu-item">
          <Link to="/viagens" rel="viagens">
            Viagens
          </Link>
        </span>
        <span className="main-menu-item">
          <Link to="/category/morar-no-exterior" rel="morar-exterior">
            Morar no Exterior
          </Link>
        </span>
        <span className="main-menu-item">
          <Link to="/category/curiosidades" rel="curiosidades">
            Curiosidades
          </Link>
        </span>
        <span className="main-menu-item">
          <Link to="/contato" rel="contato">
            Contato
          </Link>
        </span>
        <span className="main-menu-item">
          <Link to="/sobre" rel="sobre">
            Sobre
          </Link>
        </span>
        <span>
          <FontAwesomeIcon icon={faAsterisk} size="xs" />
        </span>
        <span className="level2-menu-item">
          <Link to="/privacidade" rel="privacidade">
            Privacidade
          </Link>
        </span>
        <span className="level2-menu-item">
          <Link to="/categories" rel="categorias">
            Categorias
          </Link>
        </span>
        <span className="level2-menu-item">
          <Link to="/category/reflexoes" rel="reflexoes">
            Reflexōes
          </Link>
        </span>
        <span className="level2-menu-item">
          <Link to="/cidadania-italiana" rel="cidadania-italiana">
            Cidadania Italiana
          </Link>
        </span>
      </nav>
    </div>
  )
}

export default OverlayMenu
